import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const StyledHeader = styled.header`
  padding: 0;
  padding-top: 1.45rem;
  ${(props) => props.fixed && 'position: absolute;'}
  z-index: 3000;
  text-align: center;
`

const H1 = styled.h1`
  color: black;
  font-weight: normal;
  margin: 0;
  font-size: 3.13rem;
  a {
    color: black;
  }
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`

const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: fixed;
  right: 0;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  ${(props) => (props.hideNav ? 'display: none' : 'display: flex')};

  @media screen and (max-width: 768px) {
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: white;
    text-align: center;
    display: flex;
  }
`

const UL = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`

const ListItem = styled.li`
  margin: 1.56rem;
`
export const NavLink = styled(Link)`
  font-size: 1rem;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  color: black;
`

const NavItem = ({ children, to }) => (
  <ListItem>
    <NavLink to={to} activeStyle={{ textDecoration: 'underline' }}>
      {children}
    </NavLink>
  </ListItem>
)

const Navigation = ({ hideNav }) => {
  return (
    <Nav hideNav={hideNav}>
      <UL>
        <NavItem to="/motion/">motion</NavItem>
        <NavItem to="/still/">still</NavItem>
        <NavItem to="/thoughts/">thoughts</NavItem>
      </UL>
    </Nav>
  )
}

export default Navigation
