import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const StyledHeader = styled.header`
  padding: 0;
  padding-top: 1.45rem;
  ${(props) => props.fixed && 'position: absolute;'}
  z-index: 3000;
  text-align: center;
`

const H1 = styled.h1`
  color: black;
  font-weight: normal;
  margin: 0;
  font-size: 1.57rem;
  a {
    color: black;
  }
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`

const Header = ({ className }) => (
  <StyledLink to="/" className={className}>
    <H1>Due Pinlac</H1>
  </StyledLink>
)

export default Header
