import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

const InfoContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 1000;
  display: flex;
  flex: 1;
  transition: opacity 0.5s;
  opacity: 1;
  color: white;

  article {
    a {
      color: white;
      text-decoration: none;
    }
  }
`

const InfoContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: ;
  padding: 5rem;
`

const BioPic = styled.div`
  flex: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Bio = styled.div`
  flex: 2;

  h2 {
    font-size: 2rem;
  }
  p {
    margin: 1rem;
  }
`

const Clients = styled.div`
  display: flex;
  ul {
    margin-left: 0;
    padding-left: 0;
  }
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
  }
`
const Info = ({ onClick }) => {
  const { contentfulInfo } = useStaticQuery(
    graphql`
      query InfoQuery {
        contentfulInfo(name: { eq: "Info" }) {
          bio {
            childMarkdownRemark {
              html
            }
          }
          clients
          contactInfo {
            childMarkdownRemark {
              html
            }
          }
          profile {
            fixed(width: 500) {
              src
            }
          }
        }
      }
    `
  )

  const bio = contentfulInfo.bio.childMarkdownRemark.html
  const clients = contentfulInfo.clients
  const contactInfo = contentfulInfo.contactInfo.childMarkdownRemark.html
  const profile = contentfulInfo.profile.fixed.src

  return (
    <InfoContainer onClick={onClick}>
      <InfoContent>
        <BioPic>
          <img src={profile} />
        </BioPic>
        <Bio>
          <h2>Bio</h2>
          <article
            dangerouslySetInnerHTML={{
              __html: bio,
            }}
          />
          <div>
            <h2>Clients</h2>
            <Clients>
              <p>Commercial Clients include: </p>
              <ul>
                {clients.map((client) => (
                  <li key={client}>{client}</li>
                ))}
              </ul>
            </Clients>
          </div>
          <div>
            <h2>Contact Info</h2>
            <article
              dangerouslySetInnerHTML={{
                __html: contactInfo,
              }}
            />
          </div>
        </Bio>
      </InfoContent>
    </InfoContainer>
  )
}

export default Info

/**
 query InfoQuery {
  contentfulInfo(name: {eq: "info"}) {
    bio {
      childMarkdownRemark {
        html
      }
    }
    clients
    contactInfo {
      childMarkdownRemark {
        html
      }
    }
  }
}
 */
