import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Navigation, { NavLink } from './navigation'
import Info from './info'
import Logo from './logo'
import './layout.css'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-top: ${(props) => (props.hideNav ? '0' : '100px')};
  }
`
const InfoLink = styled.div`
  position: fixed;
  right: 2.56rem;
  top: 1.56rem;
  z-index: 300;
  &:hover {
    cursor: pointer;
  }
`

const StyledLogo = styled(Logo)`
  position: fixed;
  left: 1.56rem;
  top: 50%;
  transform: translateY(-50%);

  ${(props) => (props.hideNav ? '' : '@media screen and (max-width: 768px) {')}
  top: 0;
  padding-top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: white;
  text-align: center;
  ${(props) => (props.hideNav ? '' : '}')}
`
const Layout = ({ children, hideNav = false }) => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <>
      <Container hideNav={hideNav}>
        <InfoLink to="/info" onClick={() => setShowInfo(true)}>
          info
        </InfoLink>
        <StyledLogo hideNav={hideNav} />
        <Navigation hideNav={hideNav} />
        {children}
        {showInfo && <Info onClick={() => setShowInfo(false)} />}
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
